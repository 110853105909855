<template>
  <Spinner v-show="!loaded"></Spinner>
  <div>
    <b-modal
      v-model="enableDelete"
      size="sm"
      title="Delete Static Action"
      hide-backdrop
      ok-title="Delete"
      @ok="deleteStaticAction(singleDeleteData.id)">
      Are you sure you want to delete this Static Action?
    </b-modal>
  </div>
  <b-col lg="3">
      <b-button variant="primary" class="mr-2" size="sm" :to="'/static-actions/add/'">
        Add Static Action
          <font-awesome-icon icon="pencil" />
      </b-button>
      </b-col>
  <b-row class="p-3" v-show="setting.length > 0">
    <div class="col-8 w-100 table-responsive-lg">
      <table class="table table-dark">
        <thead class="thead-dark">
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Name</th>
            <th scope="col">Block all</th>
            <th scope="col">Block all UDP</th>
            <th scope="col">Block all TCP</th>
            <th scope="col">Block all ICMP</th>
            <th scope="col">Block Specific</th>
            <th scope="col">Block Source UDP</th>
            <th scope="col">Block Source TCP</th>
            <th scope="col">Block Destination UDP</th>
            <th scope="col">Block Destination TCP</th>
            <th scope="col">Block Packet Lengths</th>
            <th scope="col">Rate Limiter</th>
            <th scope="col">Enabled</th>
            <th scope="col">Created</th>
            <th scope="col">Updated</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="element in setting" :key="element">
            <td class="text-white" scope="row">{{ element.id }}</td>
            <td class="text-white" scope="row">
              {{ element.name }}
            </td>
            <td class="text-white">
              {{ element.protocolAll == true ? "True" : "False" }}
            </td>
            <td class="text-white">
              {{ element.udpAll == true ? "True" : "False" }}
            </td>
            <td class="text-white">
              {{ element.tcpAll == true ? "True" : "False" }}
            </td>
            <td class="text-white">
              {{ element.icmpAll == true ? "True" : "False" }}
            </td>
            <td class="text-white">
              {{ element?.extraProtocols?.trim() == "" ? element?.extraProtocols : "None"}}
            </td>
            <td class="text-white">
              {{ element.udpSource == true ? "True" : "False" }}
            </td>
            <td class="text-white">
              {{ element.tcpSource == true ? "True" : "False" }}
            </td>
            <td class="text-white">
              {{ element.udpDest == true ? "True" : "False" }}
            </td>
            <td class="text-white">
              {{ element.tcpDest == true ? "True" : "False" }}
            </td>
            <td class="text-white">
              {{ element.packetLength == true ? "True" : "False" }}
            </td>
            <td class="text-white">
              {{ element.enableRateLimiter == true ? "True" : "False" }}
            </td>
            <td class="text-white">
              {{ element?.enabled ? "True" : "False" }}
            </td>
            <td class="text-white" v-if="element">
              {{ getDateString(new Date(element.createdAt).toISOString()) }}
            </td>
            <td class="text-white" v-if="element">
              {{ getDateString(new Date(element.updatedAt).toISOString()) }}
            </td>
            <td class="text-white">
              <b-button
                variant="primary"
                class="mr-3"
                size="sm"
                :to="'/static-actions/edit/' + element.id">
                <font-awesome-icon icon="pencil" />
              </b-button>
              &nbsp;
              <b-button
                variant="danger"
                class="mr-2"
                size="sm"
                @click="enableDeletion(element.id)">
                <font-awesome-icon icon="trash" />
              </b-button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </b-row>

  <div v-show="setting.length == 0 && loaded">
    <h1 class="text-white text-center mt-3">No static actions</h1>
  </div>
</template>
<script>
  import StaticActionsService from "../../services/static-actions.service";
  import "vue3-toastify/dist/index.css";
  import { notify } from "../../helpers/index";
  import { DateTime } from "luxon";

  export default {
    name: "StaticAction",

    data: function () {
      return {
        setting: [],
        fields: [
          { key: "id", label: "ID" },
          { key: "name", label: "Name" },
          { key: "protocolAll", label: "Block all" },
          { key: "udpAll", label: "Block all UDP" },
          { key: "tcpAll", label: "Block all TCP" },
          { key: "icmpAll", label: "Block all ICMP" },
          { key: "udpSource", label: "Block Source UDP" },
          { key: "tcpSource", label: "Block Source TCP" },
          { key: "udpDest", label: "Block Destination UDP" },
          { key: "tcpDest", label: "Block Destination TCP" },
          { key: "packetLength", label: "Block Packet Lengths" },
          { key: "enabled", label: "Enabled" },
          { key: "createdAt", label: "Created" },
          { key: "updatedAt", label: "Updated" },
          { key: "actions", label: "Actions" },
        ],
        enableDelete: false,
        singleDeleteData: {},
        loaded: false,
      };
    },
    computed: {
      currentUser() {
        return this.$store.state.auth.user;
      },
    },
    mounted() {
      this.getAllStaticActions();
    },
    methods: {
      enableDeletion(id) {
        (this.enableDelete = true),
          (this.singleDeleteData = {
            id: id,
          });
      },
      logOut() {
        this.$store.dispatch("auth/logout");
        this.$router.push("/login");
      },
      async getAllStaticActions() {
        const url = "static-actions";
        
        try {
          const reply = await StaticActionsService.getAllStaticActions(url);
          if (reply.status == 200 && reply.data) {
            this.setting = reply.data;
            this.loaded = true;
          }
        }catch {
          notify("Session expired", "error");
          this.logOut();
        }
      },
      async deleteStaticAction(id) {
        const url = `static-actions/${id}`;
        try {
          const response = await StaticActionsService.deleteStaticActions(url);
          if (response.status == 200 && response.data) {
            notify("Static Action Deleted Successfully");
            this.getAllStaticActions();
          }
          else {
            notify("Error deleting Static Action", "error");
          }
        }catch {
          notify("Session expired", "error");
          this.logOut();
        }
        
      },
      getDateString(date) {
        return DateTime.fromISO(date).toFormat("yyyy-M-d");
      },
      capitalize(string) {
        string.charAt(0).toUpperCase() + string.slice(1);
      },
    },
  };
</script>
<style scoped>
  .bg-secondary {
    background-color: #f7fafc !important;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    scale: 0.7;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #2196f3;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
</style>
